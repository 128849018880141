/* *:not(i) {
  font-family: Lato,"Helvetica Neue",Arial,Helvetica,sans-serif;
} */

@import url("https://fonts.googleapis.com/css?family=Open+Sans|Rock+Salt|Shadows+Into+Light|Cedarville+Cursive");

body {
  /* background-color: #e4e4e4; */
  /* background-color: #F9F9F9; */
  /* background-color: #f3f6f8; */
  /* background-color: #fbfbfb; */
  /* background-color: #e2e2e2; */
  /* background-color: #F4F8FC; */
}

h3 {
  font-size: 1.55rem;
  color: #333333;
}

h2 {
  color: #333333;
}

.ui.header {
  color: rgb(83 83 83 / 87%);
}

.progress-bar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-bar {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100px;
  height: 100px;
  border-radius: 50%;
  /* background: radial-gradient(closest-side, white 79%, transparent 80% 100%),
    conic-gradient(hotpink 75%, pink 0); */
}

/* .progress-bar::before {
  content: "75%";
} */

.box-blue {
  background-color: #dff0ff;
  color: #2185d0;
  -webkit-box-shadow: 0 0 0 1px #2185d0 inset, 0 0 0 0 transparent;
  box-shadow: 0 0 0 1px #2185d0 inset, 0 0 0 0 transparent;
}

.pdf-left-panel-wrapper {
  top: 77px;
  width: 350px;
  height: 100%;
  left: 0;
  position: fixed;
  background-color: white;
}

.pdf-prepare-wrapper {
  top: 118px;
  left: 350px;
  right: 0;
  bottom: 80px;
  position: fixed;
  overflow: auto;
}

.pdf-document-wrapper {
  max-width: 100%;
}

.pdf-annot-menu-wrapper {
  top: 75px;
  left: 350px;
  right: 0;
  height: 42px;
  position: fixed;
  background-color: rgb(191 194 204);
}

#annot-menu .ui.item.menu,
#annot-menu .ui.item.menu .item {
  margin-left: auto !important;
  margin-right: auto !important;
}

.pdf-prepare-page-wrapper {
  position: relative;
}

#prepare-page .ui.selection.dropdown > .dropdown.icon {
  top: 0.3em !important;
}

@font-face {
  font-family: "Parisienne";
  src: local("Parisienne"),
    url("../fonts/Parisienne-Regular.ttf") format("truetype");
  /* font-weight: regular; */
}

@font-face {
  font-family: "Arizonia";
  src: local("Arizonia"),
    url("../fonts/Arizonia-Regular.ttf") format("truetype");
  /* font-weight: bold; */
}

@font-face {
  font-family: "LaBelleAurore";
  src: local("LaBelleAurore"),
    url("../fonts/LaBelleAurore-Regular.ttf") format("truetype");
  /* font-weight: bold; */
}

/* input[value=""]::-webkit-datetime-edit{ color:  transparent; } */
/* input:focus::-webkit-datetime-edit{ color: transparent; }  */

.pdf-canvas {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div.react-datepicker-wrapper
  > div.react-datepicker__input-container,
.customDatePickerWidth
  > div.react-datepicker-wrapper
  > div.react-datepicker__input-container
  input {
  width: 100%;
  height: 100%;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.pdfFormField {
  position: absolute;
  color: #666;
  /* z-index: 2; */
  margin: 0;
  padding: 0;
  text-align: left;
  line-height: 0;
}

.pdfFormField.radio,
.pdfFormField.checkbox {
  /* background-color: #FFF7C8; */
  background-color: #e9efff;
  margin: -2px 0 0 -2px;
  padding: 2px;
  min-height: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  text-align: center;
  vertical-align: middle;
}

.fill-input-field {
  position: relative;
  width: 100%;
  /* height: 100%; */
  /* padding: 0 1px 0 4px; */
  /* margin: 0 0 7px 2px; */
  text-overflow: ellipsis;
  resize: none;
  /* font-size: 14px; */
  overflow: hidden;
  color: #0037b8;
  /* background-color: #FFF7C8; */
  background-color: #e9efff;
  background: rgb(148 194 244 / 20%);
  border: 1px solid transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  box-shadow: none;
  -o-box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  outline: none;
  line-height: 1em;
}

.pdfFormFieldDisplay {
  position: absolute;
  /* color: #0000FF !important; */
  color: #0037b8 !important;
  margin: 0;
  padding: 0;
  text-align: left;
  line-height: 0;
  display: table-cell;
  vertical-align: bottom;
}

.pdfFormFieldDisplay.radio,
.pdfFormFieldDisplay.checkbox {
  margin: -2px 0 0 -2px;
  padding: 2px;
  min-height: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  text-align: center;
  vertical-align: middle;
}

.textFieldDisplay {
  position: relative;
  width: 100%;
  height: 100%;
  text-overflow: ellipsis;
  resize: none;
  font-size: 14px;
  overflow: hidden;
  outline: none;
  line-height: 1em;
}

/* input[type="checkbox"] {
  accent-color: #FFF7C8;
  } */

.blink_me {
  animation: blinker 2s linear infinite;
  position: absolute;
  padding: 3px 8px;
  opacity: 1;
}

.Toastify__toast--success {
  background: #2ab0b0;
}

.Toastify__toast {
  border-radius: 10px;
  padding-left: 20px;
}

.table-row-header {
  background-color: #f0f7fe !important;
  color: #2185d0 !important;
  font-weight: bold;
}

.table-row {
}

.table-cell-header {
  background-color: #fbfbfb !important;
}

.table-cell {
}

.arrow-pointer {
  width: 90px;
  height: 30px;
  position: absolute;
  margin-left: -120px;
  margin-top: 10px;
  line-height: 1.5em;
  text-align: right;
  opacity: 0.9;
}

/* .arrow-pointer::after {
  content: '';
  position: absolute;
  left: 0; bottom: 0; width: 0; height: 0;
  border-left: 25px solid #7eb4e2;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
} */

.arrow-pointer::before {
  content: "";
  position: absolute;
  right: -14px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 15px solid rgb(103, 211, 189);
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

@keyframes blinker {
  0% {
    /* opacity: 0.6; */
    transform: translatey(0px);
  }
  50% {
    /* opacity: 0.8; */
    transform: translatey(-30px);
  }
  100% {
    /* opacity: 1; */
    transform: translatey(0px);
  }
}

.inverted.menu .item {
  color: white !important;
}

.react-pdf__Page {
  position: absolute !important;
}

/* .pdf-document-wrapper {
  overflow-y: scroll;
  height: 900px;
  background-color: #f3f6f8;
} */

.pdf-page-wrapper {
  position: relative;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.pdf-page-number {
  color: #3f5b77;
  font-size: 14px;
  padding-left: 8px;
  padding-bottom: 8px;
  text-align: center;
}

.pdf-page-container {
  position: relative;
  box-shadow: inset 0 -1.5px 0 0 #e1e7ed, 0 12px 17px 2px rgb(0 32 64 / 7%),
    0 5px 22px 4px rgb(0 32 64 / 6%), 0 7px 8px -4px rgb(0 32 64 / 7%);
  background-color: rgba(255, 255, 255, 0.5);
  margin-left: auto;
  margin-right: auto;
}

.annot-outer-wrapper {
  color: #0a2642;
  width: 100%;
  height: 100%;
  display: flex;
  outline: 2px solid transparent;
  box-sizing: content-box;
  user-select: none;
}

.annot-position-0 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

.annot-corner-circle {
  width: 4px;
  border: 2px solid;
  height: 4px;
  position: absolute;
  border-radius: 50%;
  background-color: white;
}

.annot-tl {
  top: -5px;
  left: -5px;
}

.annot-tr {
  top: -5px;
  right: -5px;
}

.annot-bl {
  left: -5px;
  bottom: -5px;
}

.annot-br {
  right: -5px;
  bottom: -5px;
}

.annot-inner-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.cursor-pointer {
  cursor: pointer !important;
}

.background-white {
  background-color: white !important;
}

.background-blue {
  background-color: #eaf6ff !important;
}

.background-green {
  background-color: #eaffeb !important;
}

.background-orange {
  background-color: #ffedde !important;
}

.background-yellow {
  background-color: #fff8db !important;
}

.background-lightgrey {
  background-color: #fafafa !important;
}

.background-lightblue-gradiant {
  background-color: #fff;
  background-image: linear-gradient(#b8d8fd, #fff);
}

.rbc-month-view {
  background-color: white !important;
}

.calendar-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #57b2ff;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.calendar-event-complete {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #1dcec6;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.ui.button.dark-blue {
  background-color: #243d89 !important;
  color: white !important;
}

.white-blue-table-header th {
  background-color: #ffffff !important;
  color: #2185d0 !important;
  padding-bottom: 6px !important;
}

.small-header th {
  font-size: 11px !important;
  color: rgb(180, 180, 180) !important;

  font-weight: 200 !important;
  /* background-color: rgb(241, 241, 241) !important; */
  /* background-color: transparent; */
  background-color: #ffffff !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.small-header-grey th {
  font-size: 11px !important;
  color: rgb(138, 138, 138) !important;
  font-weight: 200 !important;
  /* background-color: rgb(241, 241, 241) !important; */
  /* background-color: transparent; */
  /* background-color: #ffffff !important; */
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.small-header-orange th {
  font-size: 11px !important;
  color: rgb(184, 86, 29) !important;

  font-weight: 200 !important;
  /* background-color: rgb(241, 241, 241) !important; */
  /* background-color: transparent; */
  background-color: #ffeede !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

#signing-table tr td {
  border-color: rgba(255, 255, 255, 0.4) !important;
}

#signing-table tr th {
  color: #f2711c !important;
}

.ui.vertical.menu .item > i.icon {
  width: 1.18em;
  float: left;
  margin: 0 1em 0 0;
}

.inline {
  display: inline-block;
}

.ui.modal > .content {
  background: #eaeaea;
  padding: 1rem;
}

/* .ui.modal>.content>.segment {
  padding: 2em 2em;
} */

.ui.modal {
  color: #666666;
}

.ui.table {
  border-top: none !important;
  border-left: none;
  border-right: none;
}

.ui.table tr td:first-child {
  border-left: 1px solid rgba(34, 36, 38, 0.15) !important;
}

@media (max-width: 768px) {
  .ui.table tr td:first-child {
    border-left: none !important;
  }
}

.ui.table tr td:last-child {
  border-right: 1px solid rgba(34, 36, 38, 0.15) !important;
}

@media (max-width: 768px) {
  .ui.table tr td:last-child {
    border-right: none !important;
  }
}

.ui.sortable.table thead th {
  border-left: none;
}

.ui.striped.table tbody tr:nth-child(2n),
.ui.striped.table > tr:nth-child(2n) {
  background-color: rgb(244 244 244) !important;
}

.ui.tinier.image,
.ui.tinier.images .image,
.ui.tinier.images img,
.ui.tinier.images svg {
  width: 40px;
  height: auto;
  font-size: 0.65714286rem;
}

.cursive {
  font-family: "Cedarville Cursive", cursive !important;
  opacity: 1 !important;
  background: #fafafa none !important;
}

.bold {
  font-weight: bold;
}

.smaller-text {
  font-size: 9pt;
}

.smallest-text {
  font-size: 10px;
}

.document-heading {
  font-size: 14pt;
  text-align: center;
  font-weight: bold;
  margin: 10px 0;
}

.pull-right {
  float: right;
}

.float-right {
  float: right;
}

.section-heading {
  text-align: center;
  padding: 4px;
  border: solid 2px black;
  font-weight: bold;
  margin: 20px auto !important;
  font-size: 12pt;
}

.section-heading.agreement {
  width: 160px;
}

.table-row-blue {
  background-color: #f0f7fe !important;
  color: #2185d0 !important;
}

img.company-logo {
  vertical-align: top !important;
  width: 175px;
  height: 60px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__header {
  padding-top: 16px !important;
}

.react-datepicker__current-month {
  padding-bottom: 10px !important;
}

.react-datepicker-wrapper input {
  border: none;
  cursor: pointer;
}

.react-calendar {
  width: 100%;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
}

.text-dark {
  color: #666666 !important;
}

.text-orange-light {
  color: #ff9854 !important;
}

.border-none {
  border: none !important;
}

.text.center {
  text-align: center;
}

.text.align.right {
  text-align: right !important;
}

.text-small {
  font-size: 0.75rem !important;
}

.text-normal {
  font-size: 1rem !important;
}

.text-medium {
  font-size: 1.25rem !important;
}

.text-large {
  font-size: 1.75rem !important;
}

.text-big {
  font-size: 2.25rem !important;
}

.text-extra-large {
  font-size: 3.5rem !important;
}

.text-huge {
  font-size: 4rem !important;
}

.text-center {
  text-align: center !important;
}

@media (max-width: 768px) {
  .mobile-center {
    text-align: center;
  }
}

.float.right {
  float: right !important;
}

.text.muted {
  color: rgba(0, 0, 0, 0.6);
}

.text.white {
  color: #ffffff;
}
.text.grey {
  color: #cccccc;
}
.text.darkgrey {
  color: #666666;
}
.text.lightblack {
  color: #333333;
}
.text.black {
  color: #1b1c1d;
}
.text.yellow {
  color: #f2c61f;
}
.text.teal {
  color: #00b5ad;
}
.text.red {
  color: #d95c5c;
}
.text.purple {
  color: #564f8a;
}
.text.pink {
  color: #d9499a;
}
.text.orange {
  color: #e07b53;
}
.text.green {
  color: #5bbd72;
}
.text.blue {
  color: #3b83c0;
}

.mb-0 {
  margin-bottom: 0px;
}

.main {
  background-color: #fbfbfb;
  margin-top: 2rem;
}

.page-wrapper {
  background-color: #ffffff;
  padding: 40px !important;
  border: 1px #dedede solid;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .page-wrapper {
    padding: 0px !important;
  }
}

.sidebar-menu {
  height: 100vh;
  border-radius: 0px !important;
  position: absolute;
  padding-top: 20px;
  border: none !important;
}

.ui.vertical.menu .item:before {
  height: 0px;
}

.main-page-wrapper {
  padding: 3rem 6rem;
}

@media (max-width: 768px) {
  .main-page-wrapper {
    padding: 2rem 1rem;
  }
}

.secondary-page-wrapper {
  margin-left: 12em;
  padding: 3em 2em 3em 2em;
}

@media (max-width: 768px) {
  .secondary-page-wrapper {
    margin-left: 0em;
    padding: 2rem 1rem;
  }
}

/* .pdf-page-wrapper {
  padding: 3em;
} */

.ui.secondary.pointing.menu .active.item {
  border-color: #0d3374 !important;
  color: #0d3374 !important;
}

.ui.menu {
  margin: 0px;
}

.ui.menu.main-menu {
  /* background-color: #0d3374 !important; */
  /* background-color: #374357 !important; */
  /* background-color: #2f3641 !important; */
  background-color: #fafafa !important;

  /* background-color: #2c4364 !important; */
  /* background-color: #1c3354 !important; */
  /* background-color: #00508f !important;
  background-color: #27334e !important;
  background-color: #25274d !important;
  background-color: #557A95 !important; */
  /* background-color: #256089 !important; */
  padding: 0.5rem 2rem;
  border: 1px #c8c8c8 solid !important;
  text-align: center;
}

#secondary-nav .ui.vertical.menu {
  /* background-color: #eef0f4 !important; */
  /* background-color: #fbfbfb !important; */
  /* background-color: #2e85d0 !important; */
  /* background-color: #00508f !important;
  background-color: #3b729d !important;
  background-color: #4eb5ca !important;
  background-color: #2aa9c3 !important;
  background-color: #405f77 !important;
  background-color: #7893a9 !important;
  background-color: #3b729d !important;
  background-color: #7395ae !important; */
  /* background-color: #808b93 !important; */
  width: 12rem;
}

.ui.secondary.menu .active.item {
  background: #2185d0;
  color: rgb(255 255 255 / 95%);
}

.ui.secondary.menu .hover.item {
  background: #2185d0;
  color: rgb(255 255 255 / 95%);
}

.ui.menu.secondary-menu {
  background-color: #f0f5f9 !important;
  padding: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  border-top: 1px #c8c8c8 solid !important;
  border-left: 1px #c8c8c8 solid !important;
  border-right: 1px #c8c8c8 solid !important;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.align-items-center {
  align-items: center;
  justify-content: center;
}

/* ** Customize override semantic ui card .extra light grey to be more readable ** */
.cards .card .extra > * {
  color: rgba(0, 0, 0, 0.6);
}
.ui.card > .extra a:not(.ui),
.ui.cards > .card > .extra a:not(.ui) {
  color: rgba(8, 8, 50, 0.6);
}

.send-email-inputs {
  display: inline-block !important;
}

/* ***************** HOMEPAGE IMAGE HERO ********************* */

.hero-background-img {
  /* background-image: url("/assets/img-background-blue.jpeg") !important; */
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

/* Semantic UI has these classes, however they're only applicable to*/
/* grids, containers, rows and columns.*/
/* plus, there isn't any `mobile hidden`, `X hidden` class.*/
/* this snippet is using the same class names and same approach*/
/* plus a bit more but to all elements.*/
/* see https://github.com/Semantic-Org/Semantic-UI/issues/1114*/

div [class*="left floated"] {
  float: left;
  margin-left: 0.25em;
}

div [class*="right floated"] {
  float: right;
  margin-right: 0.25em;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  [class*="mobile hidden"],
  [class*="tablet only"]:not(.mobile),
  [class*="computer only"]:not(.mobile),
  [class*="large screen only"]:not(.mobile),
  [class*="widescreen only"]:not(.mobile),
  [class*="or lower hidden"] {
    display: none !important;
  }
}

/* Tablet / iPad Portrait */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  [class*="mobile only"]:not(.tablet),
  [class*="tablet hidden"],
  [class*="computer only"]:not(.tablet),
  [class*="large screen only"]:not(.tablet),
  [class*="widescreen only"]:not(.tablet),
  [class*="or lower hidden"]:not(.mobile) {
    display: none !important;
  }
}

/* Computer / Desktop / iPad Landscape */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  [class*="mobile only"]:not(.computer),
  [class*="tablet only"]:not(.computer),
  [class*="computer hidden"],
  [class*="large screen only"]:not(.computer),
  [class*="widescreen only"]:not(.computer),
  [class*="or lower hidden"]:not(.tablet):not(.mobile) {
    display: none !important;
  }
}

/* Large Monitor */
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  [class*="mobile only"]:not([class*="large screen"]),
  [class*="tablet only"]:not([class*="large screen"]),
  [class*="computer only"]:not([class*="large screen"]),
  [class*="large screen hidden"],
  [class*="widescreen only"]:not([class*="large screen"]),
  [class*="or lower hidden"]:not(.computer):not(.tablet):not(.mobile) {
    display: none !important;
  }
}

/* Widescreen Monitor */
@media only screen and (min-width: 1920px) {
  [class*="mobile only"]:not([class*="widescreen"]),
  [class*="tablet only"]:not([class*="widescreen"]),
  [class*="computer only"]:not([class*="widescreen"]),
  [class*="large screen only"]:not([class*="widescreen"]),
  [class*="widescreen hidden"],
  [class*="widescreen or lower hidden"] {
    display: none !important;
  }
}

[class*="zero padding"] {
  padding: 0 !important;
}

[class*="zero top padding"],
[class*="zero vertical padding"] {
  padding-top: 0 !important;
}

[class*="zero bottom padding"],
[class*="zero vertical padding"] {
  padding-bottom: 0 !important;
}

[class*="zero right padding"],
[class*="zero horizontal padding"] {
  padding-right: 0 !important;
}

[class*="zero left padding"],
[class*="zero horizontal padding"] {
  padding-left: 0 !important;
}

[class*="zero margin"] {
  margin: 0 !important;
}

[class*="zero top margin"],
[class*="zero vertical margin"] {
  margin-top: 0 !important;
}

[class*="zero bottom margin"],
[class*="zero vertical margin"] {
  margin-bottom: 0 !important;
}

[class*="zero right margin"],
[class*="zero horizontal margin"] {
  margin-right: 0 !important;
}

[class*="zero left margin"],
[class*="zero horizontal margin"] {
  margin-left: 0 !important;
}

/* 0.25 rem */
[class*="mini padding"] {
  padding: 0.25rem !important;
}

[class*="mini top padding"],
[class*="mini vertical padding"] {
  padding-top: 0.25rem !important;
}

[class*="mini bottom padding"],
[class*="mini vertical padding"] {
  padding-bottom: 0.25rem !important;
}

[class*="mini right padding"],
[class*="mini horizontal padding"] {
  padding-right: 0.25rem !important;
}

[class*="mini left padding"],
[class*="mini horizontal padding"] {
  padding-left: 0.25rem !important;
}

[class*="mini margin"] {
  margin: 0.25rem !important;
}

[class*="mini top margin"],
[class*="mini vertical margin"] {
  margin-top: 0.25rem !important;
}

[class*="mini bottom margin"],
[class*="mini vertical margin"] {
  margin-bottom: 0.25rem !important;
}

[class*="mini right margin"],
[class*="mini horizontal margin"] {
  margin-right: 0.25rem !important;
}

[class*="mini left margin"],
[class*="mini margin horizontal"] {
  margin-left: 0.25rem !important;
}

/* 0.5 rem */
[class*="tiny padding"] {
  padding: 0.5rem !important;
}

[class*="tiny top padding"],
[class*="tiny vertical padding"] {
  padding-top: 0.5rem !important;
}

[class*="tiny bottom padding"],
[class*="tiny vertical padding"] {
  padding-bottom: 0.5rem !important;
}

[class*="tiny right padding"],
[class*="tiny horizontal padding"] {
  padding-right: 0.5rem !important;
}

[class*="tiny left padding"],
[class*="tiny horizontal padding"] {
  padding-left: 0.5rem !important;
}

[class*="tiny margin"] {
  margin: 0.5rem !important;
}

[class*="tiny top margin"],
[class*="tiny vertical margin"] {
  margin-top: 0.5rem !important;
}

[class*="tiny bottom margin"],
[class*="tiny vertical margin"] {
  margin-bottom: 0.5rem !important;
}

[class*="tiny right margin"],
[class*="tiny horizontal margin"] {
  margin-right: 0.5rem !important;
}

[class*="tiny left margin"],
[class*="tiny horizontal margin"] {
  margin-left: 0.5rem !important;
}

/* 1 rem */
[class*="small padding"] {
  padding: 1rem !important;
}

[class*="small top padding"],
[class*="small vertical padding"] {
  padding-top: 1rem !important;
}

[class*="small bottom padding"],
[class*="small vertical padding"] {
  padding-bottom: 1rem !important;
}

[class*="small right padding"],
[class*="small horizontal padding"] {
  padding-right: 1rem !important;
}

[class*="small left padding"],
[class*="small horizontal padding"] {
  padding-left: 1rem !important;
}

[class*="small margin"] {
  margin: 1rem !important;
}

[class*="small top margin"],
[class*="small vertical margin"] {
  margin-top: 1rem !important;
}

[class*="small bottom margin"],
[class*="small vertical margin"] {
  margin-bottom: 1rem !important;
}

[class*="small right margin"],
[class*="small horizontal margin"] {
  margin-right: 1rem !important;
}

[class*="small left margin"],
[class*="small horizontal margin"] {
  margin-left: 1rem !important;
}

/* 1.5 rem */
[class*="medium padding"] {
  padding: 1.5rem !important;
}

[class*="medium top padding"],
[class*="medium vertical padding"] {
  padding-top: 1.5rem !important;
}

[class*="medium bottom padding"],
[class*="medium vertical padding"] {
  padding-bottom: 1.5rem !important;
}

[class*="medium right padding"],
[class*="medium horizontal padding"] {
  padding-right: 1.5rem !important;
}

[class*="medium left padding"],
[class*="medium horizontal padding"] {
  padding-left: 1.5rem !important;
}

[class*="medium margin"] {
  margin: 1.5rem !important;
}

[class*="medium top margin"],
[class*="medium vertical margin"] {
  margin-top: 1.5rem !important;
}

[class*="medium bottom margin"],
[class*="medium vertical margin"] {
  margin-bottom: 1.5rem !important;
}

[class*="medium right margin"],
[class*="medium horizontal margin"] {
  margin-right: 1.5rem !important;
}

[class*="medium left margin"],
[class*="medium horizontal margin"] {
  margin-left: 1.5rem !important;
}

/* 2 rem */
[class*="large padding"] {
  padding: 2rem !important;
}

[class*="large top padding"],
[class*="large vertical padding"] {
  padding-top: 2rem !important;
}

[class*="large bottom padding"],
[class*="large vertical padding"] {
  padding-bottom: 2rem !important;
}

[class*="large right padding"],
[class*="large horizontal padding"] {
  padding-right: 2rem !important;
}

[class*="large left padding"],
[class*="large horizontal padding"] {
  padding-left: 2rem !important;
}

[class*="large margin"] {
  margin: 2rem !important;
}

[class*="large top margin"],
[class*="large vertical margin"] {
  margin-top: 2rem !important;
}

[class*="large bottom margin"],
[class*="large vertical margin"] {
  margin-bottom: 2rem !important;
}

[class*="large right margin"],
[class*="large horizontal margin"] {
  margin-right: 2rem !important;
}

[class*="large left margin"],
[class*="large horizontal margin"] {
  margin-left: 2rem !important;
}

/* 4 rem */
[class*="big padding"] {
  padding: 4rem !important;
}

[class*="big top padding"],
[class*="big vertical padding"] {
  padding-top: 4rem !important;
}

[class*="big bottom padding"],
[class*="big vertical padding"] {
  padding-bottom: 4rem !important;
}

[class*="big right padding"],
[class*="big horizontal padding"] {
  padding-right: 4rem !important;
}

[class*="big left padding"],
[class*="big horizontal padding"] {
  padding-left: 4rem !important;
}

[class*="big margin"] {
  margin: 4rem !important;
}

[class*="big top margin"],
[class*="big vertical margin"] {
  margin-top: 4rem !important;
}

[class*="big bottom margin"],
[class*="big vertical margin"] {
  margin-bottom: 4rem !important;
}

[class*="big right margin"],
[class*="big horizontal margin"] {
  margin-right: 4rem !important;
}

[class*="big left margin"],
[class*="big horizontal margin"] {
  margin-left: 4rem !important;
}

/* 6 rem */
[class*="huge padding"] {
  padding: 6rem !important;
}

[class*="huge top padding"],
[class*="huge vertical padding"] {
  padding-top: 6rem !important;
}

[class*="huge bottom padding"],
[class*="huge vertical padding"] {
  padding-bottom: 6rem !important;
}

[class*="huge right padding"],
[class*="huge horizontal padding"] {
  padding-right: 6rem !important;
}

[class*="huge left padding"],
[class*="huge horizontal padding"] {
  padding-left: 6rem !important;
}

[class*="huge margin"] {
  margin: 6rem !important;
}

[class*="huge top margin"],
[class*="huge vertical margin"] {
  margin-top: 6rem !important;
}

[class*="huge bottom margin"],
[class*="huge vertical margin"] {
  margin-bottom: 6rem !important;
}

[class*="huge right margin"],
[class*="huge horizontal margin"] {
  margin-right: 6rem !important;
}

[class*="huge left margin"],
[class*="huge horizontal margin"] {
  margin-left: 6rem !important;
}
